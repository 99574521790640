<template>
  <div>
    <b-button @click="$router.push('/campanhas/base-de-dados/novo')">Adicionar</b-button>
    <Card class="mt-2" :title="'Listas de Dados'">
      <b-table class="mt-2" small striped hover :items="files" :fields="fields">
        <template #cell(id)="data">
          <b-dropdown
            class="p-0 m-0 d-flex"
            size="md"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <fa-icon size="1x" icon="bars" />
            </template>
            <b-dropdown-item
              v-if="permissions.includes('editar')"
              @click="
                $router.push(`/configuracoes/perfil/${data.item.id}/editar`)
              "
              >Editar</b-dropdown-item
            >
          </b-dropdown>
        </template>
      </b-table>
    </Card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "#",
          thStyle: {
            width: "5%",
            textAlign: "center",
          },
          thClass: "text-muted small",
        },
        {
          key: "name",
          label: "Nome",
          thStyle: {
            width: "15%",
          },
          thClass: "text-muted small",
          tdClass: "align-middle",
        },
        {
          key: "description",
          label: "Descrição",
          thStyle: {
            width: "80%",
          },
          thClass: "text-muted small",
          tdClass: "align-middle",
        },
      ],
    };
  },
  computed: {
    state() {
      return this.$store.state.campaigns;
    },
    files() {
      return this.state.campaignFiles;
    },
    permissions() {
      let permissions = ["editar"];
      // try {
      //   const response =
      //     this.$store.state.auth.burgerPermissions.configuracoes.perfil.habilitar;
      //   if (response) {
      //     permissions = response;
      //   }
      // } catch (e) {}

      return permissions;
    },
  },
  mounted() {
    this.$store.dispatch("campaigns/list_init");
  },
};
</script>

<style lang="scss" scoped>
.table th,
.table td {
  border-top: none !important;
}
</style>